
import { DistributedDataService, httpHelper } from './http.service';
import { ENDPOINT } from './urls.service';

class PetService extends DistributedDataService {
  async list(params: URLSearchParams) {
    return httpHelper.get(`${ENDPOINT}/catalog-secured/v1/inventory`, params);
  }

  async get(id: string) {
    return httpHelper.get(`${ENDPOINT}/catalog-secured/v1/inventory/${id}`);
  }

  async create(payload: any) {
    return httpHelper.put(`${ENDPOINT}/catalog-secured/v1/inventory`, payload);
  }

  async update(id: string, payload: any) {
    return httpHelper.post(`${ENDPOINT}/catalog-secured/v1/inventory/${id}`, payload);
  }

  async report(params: URLSearchParams) {
    return httpHelper.get(`${ENDPOINT}/catalog-secured/v1/report`, params);
  }

  async search(text: string, prev?: string, oppened?: boolean) {
    return httpHelper.cacheWrapper(`38dadc68_${text}_${prev}_${oppened}`, () => httpHelper.get(`${ENDPOINT}/catalog-secured/v1/search`, new URLSearchParams([['text', text], ['prev', prev || ''], ['oppened', oppened ? 'true' : '']])), 60000);
  }

  async getJournal(params: URLSearchParams) {
    return httpHelper.get(`${ENDPOINT}/catalog-secured/v1/journal`, params);
  }

  async getJournalCount(params: URLSearchParams) {
    return httpHelper.get(`${ENDPOINT}/catalog-secured/v1/journal-count`, params);
  }

  async getChangeRequests(params: URLSearchParams) {
    return httpHelper.get(`${ENDPOINT}/catalog-secured/v1/change-request`, params);
  }

  async getChangeRequest(id: string) {
    return httpHelper.get(`${ENDPOINT}/catalog-secured/v1/change-request/${id}`);
  }

  async saveChangeRequest(id: string, payload: any) {
    return httpHelper.post(`${ENDPOINT}/catalog-secured/v1/change-request/${id}`, payload);
  }

  async countChangeRequests() {
    return httpHelper.get(`${ENDPOINT}/catalog-secured/v1/state/change-request`);
  }

  async evaluateName(data: { name: string, type: string, _id?: string }) {
    return httpHelper.post(`${ENDPOINT}/catalog-secured/v1/evaluate`, data);
  }

  async getResolutions() {
    return httpHelper.cacheWrapper('2a31e35b', () => httpHelper.get(`${ENDPOINT}/catalog-secured/v1/resolution`));
  }

  async getMyStats() {
    return httpHelper.cacheWrapper('a2447d2f', () => httpHelper.get(`${ENDPOINT}/catalog-secured/v1/my-stats`));
  }

  async getTypes() {
    return httpHelper.cacheWrapper('8a95ed06', () => httpHelper.get(`${ENDPOINT}/catalog-secured/v1/type`));
  }

  async getMyStatus(type: string) {
    return httpHelper.get(`${ENDPOINT}/catalog-secured/v1/type/${type}/my-status`, new URLSearchParams([['type', type || 'ALL']]));
  }

  async getStatus(type: string) {
    return httpHelper.cacheWrapper(`6b975014_${type}`, () => httpHelper.get(`${ENDPOINT}/catalog-secured/v1/type/${type}/status`, new URLSearchParams([['type', type || 'ALL']])));
  }

  async getColor(type: string) {
    return httpHelper.cacheWrapper(`9505ffe1_${type}`, () => httpHelper.get(`${ENDPOINT}/catalog-secured/v1/type/${type}/color`, new URLSearchParams([['type', type]])));
  }

  async getSkin(type: string) {
    return httpHelper.cacheWrapper(`9622aced_${type}`, () => httpHelper.get(`${ENDPOINT}/catalog-secured/v1/type/${type}/skin`, new URLSearchParams([['type', type]])));
  }

  async getBreed(type: string) {
    return httpHelper.cacheWrapper(`2dd61680_${type}`, () => httpHelper.get(`${ENDPOINT}/catalog-secured/v1/type/${type}/breed`, new URLSearchParams([['type', type]])));
  }

  async getStatusRequredFields(type: string, status: string) {
    return httpHelper.cacheWrapper(`448bed4e_${type}_${status}`, () => httpHelper.get(`${ENDPOINT}/catalog-secured/v1/type/${type}/status/${status}/field`, new URLSearchParams([['type', type], ['status', status]])));
  }

  async getForums() {
    return httpHelper.cacheWrapper('b53f46f7', () => httpHelper.get(`${ENDPOINT}/catalog-secured/v1/forum`));
  }

  async getTopics(id: string, originalValue: string) {
    return httpHelper.cacheWrapper(`634fdf31_${id}_${originalValue}`, () => httpHelper.get(`${ENDPOINT}/catalog-secured/v1/forum/${id}`, new URLSearchParams([['prev', originalValue]])));
  }

  async getTopic(id: string) {
    return httpHelper.cacheWrapper(`065f512d_${id}`, () => httpHelper.get(`${ENDPOINT}/catalog-secured/v1/topic/${id}`));
  }

  async team() {
    return httpHelper.cacheWrapper('9b71f194', () => httpHelper.get(`${ENDPOINT}/catalog-secured/v1/team`));
  }
}

export const petService = new PetService();
export default petService;