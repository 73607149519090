
import { DistributedDataService, httpHelper } from './http.service';
import { ENDPOINT } from './urls.service';

class DocumentService extends DistributedDataService {
  async list(params: URLSearchParams) {
    return httpHelper.get(`${ENDPOINT}/document-secured/v1/document`, params);
  }

  async get(id: string) {
    return httpHelper.get(`${ENDPOINT}/document-secured/v1/document/${id}`);
  }

  async create(payload: any) {
    return httpHelper.put(`${ENDPOINT}/document-secured/v1/document`, payload);
  }

  async update(id: string, payload: any) {
    return httpHelper.post(`${ENDPOINT}/document-secured/v1/document/${id}`, payload);
  }

  async delete(id: string) {
    return httpHelper.delete(`${ENDPOINT}/document-secured/v1/document/${id}`);
  }

  async report(params: URLSearchParams) {
    return httpHelper.get(`${ENDPOINT}/document-secured/v1/report`, params);
  }

  async types(group?: string) {
    return httpHelper.cacheWrapper(`9d0471fb_${group || 'ALL'}`, () => httpHelper.get(`${ENDPOINT}/document-secured/v1/type`, group ? new URLSearchParams([['group', group]]) : undefined));
  }

  async stages() {
    return httpHelper.cacheWrapper('0d21e1d1', () => httpHelper.get(`${ENDPOINT}/document-secured/v1/stage`));
  }

  async search(text: string, username?: string) {
    return httpHelper.cacheWrapper(`739d99a9_${text}_${username}`, () => httpHelper.get(`${ENDPOINT}/document-secured/v1/search`, new URLSearchParams([['text', text], ['username', username || '']])));
  }
}

export const documentService = new DocumentService();
export default documentService;