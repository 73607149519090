export enum UserRole {
    SUPERUSER = 'ROLE_ADMIN',
    SUPERVISOR = 'ROLE_SUPERVISOR',
    USER = 'ROLE_USER',
    IMPERSONALIZED_USER = 'ROLE_IMPERSONALIZED_USER',

    COUPONS = 'ROLE_COUPON_USER',
    COUPON_CREATE = 'ROLE_COUPON_CREATE',
    COUPON_VIEW_ALL = 'ROLE_COUPON_VIEW_ALL',
    COUPON_VIEW_REPORTS = 'ROLE_COUPON_VIEW_REPORTS',
    COUPON_FUND = 'ROLE_COUPON_FUND_USER',
    COUPON_FUND_CREATE = 'ROLE_COUPON_FUND_CREATE',
    COUPON_FUND_VIEW_ALL = 'ROLE_COUPON_FUND_VIEW_ALL',
    COUPON_SUPERVISOR = 'ROLE_COUPON_SUPERVISOR',

    INVENTORY = 'ROLE_CATALOG_USER',
    INVENTORY_HORSES = 'ROLE_CATALOG_USER_HHT',
    INVENTORY_CREATE = 'ROLE_CATALOG_USER_CREATE',
    INVENTORY_VIEW_ALL = 'ROLE_CATALOG_VIEW_ALL',
    INVENTORY_VIEW_REPORTS = 'ROLE_CATALOG_VIEW_REPORTS',
    INVENTORY_SUPERVISOR = 'ROLE_CATALOG_MODERATOR',


    CLAIM = 'ROLE_CLAIM_USER',
    CLAIM_CREATE = 'ROLE_CLAIM_CREATE',
    CLAIM_VIEW_ALL = 'ROLE_CLAIM_VIEW_ALL',
    CLAIM_VIEW_REPORTS = 'ROLE_CLAIM_VIEW_REPORTS',
    CLAIM_SUPERVISOR = 'ROLE_CLAIM_SUPERVISOR',

    DOCUMENT = 'ROLE_DOCUMENT_USER',
    DOCUMENT_CREATE = 'ROLE_DOCUMENT_CREATE',
    DOCUMENT_VIEW_ALL = 'ROLE_DOCUMENT_VIEW_ALL',
    DOCUMENT_VIEW_REPORTS = 'ROLE_DOCUMENT_VIEW_REPORTS',
    DOCUMENT_SUPERVISOR = 'ROLE_DOCUMENT_SUPERVISOR',

    CONSENT_SUPERVISOR = 'ROLE_CONSENT_SUPERVISOR',
    PAGE_SUPERVISOR = 'ROLE_PAGE_SUPERVISOR',
    PAYMENT_VIEW_REPORTS = 'ROLE_PAYMENT_VIEW_REPORTS',

    JOURNAL_ENCHANTED = 'ROLE_JOURNAL_ENCHANTED',
    JOURNAL = 'ROLE_JOURNAL',
}